/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/custom.css'

import twoStrokes from "../images/knife-and-fork.svg"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <p className="text-center">
            <img src={twoStrokes} className="two brush strokes" alt="brush strokes" width="100" />
          </p>
      <footer>
        <p className="text-center">© {new Date().getFullYear()} P.S Catering LLC <br />
        Site designed by <a href="Nautilus Designs">Nautilus Designs</a></p>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
