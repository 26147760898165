import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import logo from "../images/psc-logo.svg"

const Header = ({ siteTitle }) => (
  <header className="text-center mt-3">
    <Link
      to="/"
    >
      <img src={logo} alt={siteTitle} width="350" />
    </Link>
    
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
